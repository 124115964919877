import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout, Article, Wrapper, Button, SectionTitle } from '../components'
import { withStyles } from '@material-ui/core/styles'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";

const Hero = styled.div`
  height: 8vh;
  grid-column: 2;
  overflow: hidden;
  display: flex;
  align-items: center;

  h1 {
    color: ${props => props.theme.colors.white};
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 7px 14px rgba(0, 0, 0, 0.25);
  }
  small {
    margin-top: 0.45rem;
    padding-left: 2rem;
    color: ${props => props.theme.colors.white};

    del {
      color: red;
    }
  }
`

const Editor = styled.div`
  height: 75vh;
  grid-column: 2;
  overflow: hidden;
  padding: 2rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 40px 120px rgba(0, 0, 0, 0.75);
  border-radius: 0.25rem;
`

const LiveEdit = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  strong {
    color: red;
    text-decoration: line-through;
  }
`

class Writer extends React.Component {
  constructor() {
    super();
    this.state = { 
      html: ``,
      focus: true,
      editable: true
    }
    this.styles = {
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'scroll',
      flex: '1 1 auto',
      outline: 'none',
      caretColor: 'rgb(84,187,231)'
    }
  }

  markWords(words) {
    let html = words.replace(/<\/?strong>/gi, '')
    const bvrbs = ['am', 'is', 'isn\'t', 'are', 'aren\'t', 'was', 'wasn\'t', 'were', 'weren\'t', 'be', 'being', 'been', 'it\'s', 'you\'re', 'they\'re', 'we\'re', 'I\'m', 'he\'s', 'she\'s', 'there\'s', 'here\'s', 'where\'s', 'how\'s', 'what\'s', 'who\'s', 'that\'s', 'ain\'t', 'hain\'t', 'whatcha', 'yer']

    bvrbs.forEach(
      (bvrb) => {
        let exp = RegExp('\\b(' + bvrb + ')\\b', 'gi')
        html = html.replace(exp, mtch => {
          console.log('WORD MATCH: ' + mtch)
          return '<strong>' + mtch + '</strong>'
        })
      }
    )

    this.setState({ html: html });
  }

  handleChange = evt => {
    this.markWords(evt.target.value)
    evt.target.selectionStart = 0 
    evt.target.selectionEnd = 0
  }

  sanitizeConf = {
    allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
    allowedAttributes: { a: ["href"] }
  }

  sanitize = () => {
    this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
  }

  toggleEditable = () => {
    this.setState({ editable: !this.state.editable });
  }

  componentDidMount() {
    this.focusDiv()
  }

  componentDidUpdate() {
    if(this.state.active)
      this.focusDiv()
  }

  focusDiv() {
    ReactDOM.findDOMNode(this.refs.theEditor).focus()
  }

  render = () => {
    return (
      <ContentEditable
        ref="theEditor"
        className="editable"
        style={this.styles}
        html={this.state.html} // innerHTML of the editable div
        disabled={!this.state.editable} // use true to disable edition
        onChange={this.handleChange} // handle innerHTML change
        // onBlur={this.sanitize}
      />
    )
  }
}

const IndexPage = ({
}) => (
  <Layout>
    <Wrapper>
      <Hero>
        <h1>E-Prime</h1>
        <small>writing without the crutch of <del>be</del> verbs</small>
      </Hero>
      <Editor>
        <LiveEdit>
          <Writer />
        </LiveEdit>
      </Editor>
    </Wrapper>
  </Layout>
)

export default IndexPage

